<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg visitor-management-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name:
            this.backLinkName.indexOf('r_edit') !== -1
              ? 'r_visitor-edit-languages-help-online'
              : 'r_visitor-add-languages-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "reception-desks",
              "language"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <section class="languages" v-if="sortedLanguages && sortedLanguages.length">
      <ul class="clebex-item-section pill">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="lang in sortedLanguages"
          :key="lang.id"
        >
          <button class="clebex-item-content-wrapper" @click="selectLang(lang)">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-hea1-arrow-left" width="12" height="12"></icon>
              </span>
            </span>
            <span class="label">
              <span class="highlight">{{ lang.name }}</span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SelectLanguage",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      helpSlug: "visitor-languages"
    };
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState("visitorManagement", ["visitor"]),
    ...mapState("language", ["languages"]),
    sortedLanguages() {
      if (this.languages && this.languages.data && this.languages.data.length) {
        let languages = this.languages.data.slice(0);

        return languages.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        });
      }
      return null;
    }
  },
  methods: {
    selectLang(language) {
      this.$store.commit(
        "visitorManagement/setVisitor",
        { ...this.visitor, language: language },
        { root: true }
      );

      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  }
};
</script>
